import React, {PureComponent, ReactNode} from "react";

type IconButtonProps = {
    text: string,
    color: string,
    textcolor: string,
    onClick?: () =>  void
};


export class IconButton extends PureComponent<IconButtonProps> {

    render(): ReactNode {
        const {
            text,
            color,
            textcolor,
            onClick
        } = this.props


        return (
            <div className={"iconButton text-base leading-6 font-medium w-full"}>
                <button onClick={onClick} className={`${color} rounded-md p-4 md:p-2 text-${textcolor} w-full text-center`}>
                    <i className={'fa-kit fa-magnifying-glass text-white mr-3 inline-block relative'}/>
                    {text}
                </button>
            </div>
        );
    }
}