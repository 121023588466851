import React, {FunctionComponent, useState} from "react";
import {IconButton} from "../../common/IconButton";
import heroImage from "../../../public/assets/images/werkstatt-in-der-naehe.webp";
import heroL from "../../../public/assets/images/werkstatt-in-der-naehe-L.webp";
import heroLg from "../../../public/assets/images/werkstatt-in-der-naehe-Lg.webp";
import heroXl from "../../../public/assets/images/werkstatt-in-der-naehe-Xl.webp";
import hero2Xl from "../../../public/assets/images/werkstatt-in-der-naehe-2xl.webp";
import heroImage1 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe1.webp";
import heroL1 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L1.webp";
import heroLg1 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg1.webp";
import heroXl1 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl1.webp";
import hero2Xl1 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl1.webp";
import heroImage7 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe7.webp";
import heroL7 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L7.webp";
import heroLg7 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg7.webp";
import heroXl7 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl7.webp";
import hero2Xl7 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl7.webp";
import heroImage2 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe2.webp";
import heroL2 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L2.webp";
import heroLg2 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg2.webp";
import heroXl2 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl2.webp";
import hero2Xl2 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl2.webp";
import heroImage3 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe3.webp";
import heroL3 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L3.webp";
import heroLg3 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg3.webp";
import heroXl3 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl3.webp";
import hero2Xl3 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl3.webp";
import heroImage4 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe4.webp";
import heroL4 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L4.webp";
import heroLg4 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg4.webp";
import heroXl4 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl4.webp";
import hero2Xl4 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl4.webp";
import heroImage5 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe5.webp";
import heroL5 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L5.webp";
import heroLg5 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg5.webp";
import heroXl5 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl5.webp";
import hero2Xl5 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl5.webp";
import heroImage6 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe6.webp";
import heroL6 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-L6.webp";
import heroLg6 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Lg6.webp";
import heroXl6 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-Xl6.webp";
import hero2Xl6 from "../../../public/assets/images/heroSlider/werkstatt-in-der-naehe-2xl6.webp";
import {LocationSearchProps} from "../../common/FormElements/LocationFilter";
import dynamic from "next/dynamic";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {ImageSize} from "@App/types";

const LocationFilter = dynamic<LocationSearchProps>(() => import("@App/components/common/FormElements/LocationFilter") as any, {ssr:true })

export type HeroProps = {};
type Place = {
    id: string|number,
    name: string,
}

export const Hero: FunctionComponent<HeroProps> = ((props: HeroProps) => {


    const [service, setService] = useState({id: '-1', url:'', name: 'Services'})
    const [place, setPlace] = useState<Place>({id: '-1', name:''})

    const submit = ():void => {
        if(service.url && place.id !== '-1'){
            window.location.href = `${service.url}?place=${place.id}&service=${service.id}`;
        }
    }

    const selectService = (serviceId:string):void => {
        let service = services.find(service => service.id === serviceId);

        if (service){
            setService(service);
        }
    }


    const services = [
        {id: '-1', url: '', name: 'Bitte wählen Sie einen Service'},
        {id: '132', url: 'https://werkstatt.repareo.de/hu-au-tuev/werkstaetten', name: 'TÜV (HU/AU)'},
        {id: '13', url: 'https://werkstatt.repareo.de/inspektion', name: 'Inspektion'},
        {id: '12', url: 'https://werkstatt.repareo.de/oelwechsel', name: 'Ölwechsel'},
        {id: '1', url: 'https://werkstatt.repareo.de/reifenwechsel/werkstaetten', name: 'Reifenwechsel'},
        {id: '34', url: 'https://werkstatt.repareo.de/autoglas-reparatur/werkstaetten', name: 'Glasreparatur'},
        {id: '14', url: 'https://werkstatt.repareo.de/zahnriemenwechsel', name: 'Zahnriemenwechsel'},
        {id: '15', url: 'https://werkstatt.repareo.de/bremsenwechsel', name: 'Bremsenwechsel'},
        {id: '118', url: 'https://werkstatt.repareo.de/kupplung', name: 'Kupplungswechsel'},
        {id: '79', url: 'https://werkstatt.repareo.de/bremsfluessigkeit-wechseln', name: 'Bremsflüssigkeit wechseln'},
        {id: '92', url: 'https://werkstatt.repareo.de/zuendkerzen-wechseln', name: 'Zündkerzen wechseln'},
        {id: '56', url: 'https://werkstatt.repareo.de/anhaengerkupplung-nachruesten/werkstaetten', name: 'Anhängerkupplung nachrüsten'},
        {id: '2', url: 'https://werkstatt.repareo.de/termin/werkstaetten', name: 'Sonstiges / Termin'},
    ]

    const images = [
        { src: heroImage.src, l: heroL.src, lg: heroLg.src, xl: heroXl.src, xxl: hero2Xl.src, alt: 'Autohaus Haeusler'},
        { src: heroImage1.src, l: heroL1.src, lg: heroLg1.src, xl: heroXl1.src, xxl: hero2Xl1.src, alt: 'Autohaus Bayerngarage'},
        { src: heroImage7.src, l: heroL7.src, lg: heroLg7.src, xl: heroXl7.src, xxl: hero2Xl7.src, alt: 'Vergölst'},
        { src: heroImage2.src, l: heroL2.src, lg: heroLg2.src, xl: heroXl2.src, xxl: hero2Xl2.src, alt: 'Autohaus Minrath'},
        { src: heroImage3.src, l: heroL3.src, lg: heroLg3.src, xl: heroXl3.src, xxl: hero2Xl3.src, alt: 'Bosch Car Service'},
        { src: heroImage4.src, l: heroL4.src, lg: heroLg4.src, xl: heroXl4.src, xxl: hero2Xl4.src, alt: 'Driver Center Muenchen'},
        { src: heroImage5.src, l: heroL5.src, lg: heroLg5.src, xl: heroXl5.src, xxl: hero2Xl5.src, alt: 'Euromaster'},
        { src: heroImage6.src, l: heroL6.src, lg: heroLg6.src, xl: heroXl6.src, xxl: hero2Xl6.src, alt: 'Autohaus Moll'},
    ]

    return (
        <div className={'hero flex px-6 md:px-12 pb-16 md:pb-24 flex-col md:flex-row items-center'}>
            <div
                className={'hero__contentContainer md:my-16 pb-8 md:p-8 rounded-lg z-10 md:-mr-24 bg-white flex items-start flex-col grow-0 flex-shrink-0 md:w-100 md:shadow-2xl'}>
                <h1 className={'text-3xl leading-9 font-semibold md:text-4xl md:leading-10 font-serif text-gray-900 pb-2'}>Finde
                    die beste Werkstatt in deiner Nähe bei repareo</h1>
                <p className={'text-base leading-6 font-normal text-gray-500 pb-6'}>Vereinbare Termine für Autohäuser,
                    freie Werkstätten und Werkstattketten direkt online und mit transparenten Preisen.</p>
                <LocationFilter initialLocation={null} onSubmit={(place) => setPlace({id: place.id, name: place.name})} placeholder={'PLZ oder Stadt eingeben'} label padding={'pb-3 pt-6'} />
                <label className={'w-full relative'}>
                    <span className={'absolute left-3 text-xs leading-4 top-2 font-medium text-gray-700'}>Service</span>
                    <select name={'serviceSelect'} title={'serviceSelect'} id={'serviceSelect'} value={service.id}
                            onChange={(e) => {
                               selectService(e.target.value)
                            }}
                            className={'appearance-none w-full pb-3 pt-6 px-3 pr-8 text-gray-500 rounded-[6px] shadow-sm focus:rounded-b-none focus:border-b-neutral-100 border border-gray-300 focus:border-primary focus:outline-none mb-2'}>
                                {services.map((service) => (
                                    <option key={service.id} value={service.id} disabled={service.id === '-1'}>{service.name}</option>
                                ))}
                               </select>
                </label>
                    <IconButton text={'Werkstatt suchen'} color={'bg-primary-600'} textcolor={'white'} onClick={submit}/>
            </div>
            <div
                className={'hero__imageContainer flex grow z-0 aspect-3/2 md:aspect-auto w-full'}>
                <Splide options={{
                    rewind: true,
                    lazyLoad: 'nearby',
                    preloadPages: 0,
                    type: 'slide',
                    perPage: 1,
                    classes: {
                        arrows: 'splide__arrows hidden md:block z-10',
                    },
                    drag: true,
                    arrows: true,
                    autoplay: true,
                    interval: 5000
                }}>
                    {images.map((image: any, index: number) => (
                        <SplideSlide key={'heroSlider' + index} className={'rounded-md z-10'}>
                            <picture className={'rounded-md w-full h-full object-cover'}>
                                <source media="(max-width: 640px)" srcSet={`${image.src}`}  data-splide-lazy={`${image.src}`}/>
                                <source media="(min-width: 640px) and (max-width: 768px)" srcSet={`${image.l}`} data-splide-lazy={`${image.l}`}/>
                                <source media="(min-width: 769px) and (max-width: 1024px)" srcSet={`${image.lg}`} data-splide-lazy={`${image.lg}`}/>
                                <source media="(min-width: 1025px) and (max-width: 1280px)" srcSet={`${image.xl}`} data-splide-lazy={`${image.xl}`}/>
                                <source media="(min-width: 1281px)" srcSet={`${image.xxl}`} data-splide-lazy={`${image.xxl}`}/>
                                <img src={image.xxl} alt="" width={1040} height={617} data-splide-lazy={`${image.xxl}`}/>
                            </picture>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    )
})
